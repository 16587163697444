import React, { useState } from 'react'
import Link from '../../components/link/link'
import styled from 'styled-components'
import Burger from './burger'
import Desc from '../../components/desc/desc'
import ContentWrap from '../../components/contentWrap/contentWrap'

import NavMobile from './navMobile'
import NavDeskt from './navDeskt'

import imgLogo from '../../images/LOGO.png'

const headerHeight = '60px'

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => headerHeight};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 7px 40px;
  z-index: 189;
  /* background-color: rgba(0, 0, 0, 0.85); */
  background-color: rgba(37, 40, 61, 0.97);
`
const StyledContentWrap = styled(ContentWrap)`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  @media (min-width: 768px) {
    justify-content: space-between;
  }
  @media (min-width: 1400px) {
    max-width: 1050px;
  }
`

const Logo = styled.img`
  /* height: 60%; */
  /* max-width: 50%; */
  /* margin-left: 140px; */

  @media (min-width: 768px) {
    /* display: none; */
    /* margin-right: auto; */
  }
`
const ImgLink = styled(Link)`
  /* background-color: #fff; */
  max-width: 150px;
`
const data = ['', 'o-nas', 'kursy', 'kontakt']

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Burger
        className={isOpen && 'is-active'}
        onClick={() => setIsOpen(!isOpen)}
      />
      <Header>
        <StyledContentWrap>
          <ImgLink to="/">
            <Logo src={imgLogo} />
          </ImgLink>
          <NavDeskt data={data} />
        </StyledContentWrap>
      </Header>

      <NavMobile isOpen={isOpen} data={data} />
    </>
  )
}

export default Nav
