import React from 'react'

import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'
import theme from './theme'
import Nav from '../partials/nav/nav'

import config from 'react-reveal/globals'

config({ ssrFadeout: true })

const Global = createGlobalStyle`
*,*::after,*::before,p,a,h1,h2,h3,h4{
    margin:0;
    padding:0;
    box-sizing:border-box;
    font-family: 'Jost', sans-serif;

}
body{
  /* margin-top:60px; */
}
a{
  color:inherit;
  text-decoration:none;
  border:none;
  transition:.2s;
  &:hover{
    text-decoration:none;
    opacity:0.8
  }
}
img{
    max-width:100%;
}
a,button{
  outline-color:rgba(0,0,0,0);
  &:hover{
    cursor:pointer;
  }
}
ul{
  list-style:none;
}

input,button,textarea{
  border:none;
  outline-color:transparent;
  font:inherit;
  &:focus{
    border:none;
  outline-color:transparent;

  }
}
input{
  border-color:#fff;
}
label{
  margin:0;
}
strong{
  color:#FDE804;
  /* color:${theme.colorYellow}; */
  /* color:${theme.colorBlack}; */
  /* font-weight:700; */
  /* font-weight:auto; */
  font-weight:inherit;
}
.title-wrap{
  /* @media(min-width:1200px){
    padding-bottom:60px;
  } */
}

.title-Kontakt{
@media(min-width:1400px){
  /* display:grid; */
  /* grid-template-areas:'banner banner''form data'; */
  /* justify-content:center; */
  /* align-items:flex-start; */
  /* align-content:center; */
  /* max-width:900px; */
}
}
.cms-text{
  p{
    margin:15px 0;
  }
  strong{
    font-weight:inherit;
    color:${theme.colorYellowDark}
  }
  em{
    font-style:normal;
  color:${theme.colorBlack};
  font-weight:inherit;
  }
  /* ul{
    list-style: inside;
  } */
  
  ul>li{
    /* display:flex; */
    /* align-items:center; */
    margin:10px 0;
    &::before{
      content:'';
      display:inline-block;
      width:10px;
      height:2.5px;
      border-radius:10%;
      /* background-color:${theme.colorYellow}; */
      background-color:${theme.colorBlack};
      margin: 0 12px 6px 0;
    }
  }
  h3{
  font-size:22px;
  text-transform: uppercase;
  font-weight: 500;
  position: relative;
  color:${theme.colorBlack};
  margin-top:20px;
  margin-bottom:40px;
  @media(min-width:1024px){
    margin-top:40px;
  }
 
  

  &::before {
    /* content: ${props => (props.yellowRect ? '""' : null)}; */
    content:"";
    position: absolute;
    left: -10px;
    top: 50%;
    z-index: 150;
    transform: translateY(-50%);
    height: 80%;
    width: 3px;
    background-color: ${({ theme }) => theme.colorYellow};
    /* background-color: ${({ theme }) => theme.colorOrange}; */
  }
  @media (min-width: 768px) {
    font-size: ${props => (props.h2 ? '34px' : '23px')};
  }
  @media (min-width: 960px) {
    font-size: ${props => (props.h2 ? '36px' : '24px')};
  }
  @media (min-width: 1200px) {
    font-size: ${props => (props.h2 ? '38px' : '25px')};
  }
  @media (min-width: 1400px) {
    font-size: ${props => (props.h2 ? '43px' : '26px')};
    margin-bottom: ${props => (props.h2 ? '30px' : null)};
  }
  @media (min-width: 1600px) {
    font-size: ${props => (props.h2 ? '48px' : '26px')};
    /* margin-bottom: ${props => (props.h2 ? '40px' : null)}; */
  }
  }
}
`

const Layout = props => {
  if (process.env.NODE_ENV !== 'development') console.log = function () {}
  return (
    <ThemeProvider theme={theme}>
      <>
        <Global />
        <Helmet prefix="og: http://ogp.me/ns#">
          <html prefix="og: http://ogp.me/ns#" lang="pl" />
          <title>
            {`${
              props.title === 'Strona Główna' ? '' : props.title + ' | '
            }  OSK Szóstka`}
          </title>
          <meta
            property="og:title"
            content={`${
              props.title === 'Strona Główna' ? '' : props.title + ' | '
            }  OSK Szóstka`}
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="http://www.oskszostka.pl/" />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            href="https://fonts.googleapis.com/css2?family=Jost:wght@400;500&display=swap"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/hamburgers/1.1.3/hamburgers.min.css"
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
          <script
            async
            defer
            crossorigin="anonymous"
            src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v10.0&appId=958330634593680&autoLogAppEvents=1"
            nonce="yptDENdD"
          ></script>
        </Helmet>
        <Nav />
        {/* <StickyBanner /> */}
        <div className={`title-wrap title-${props.title}`}>
          {props.children}
        </div>
      </>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  title: PropTypes.string,
}

export default Layout
