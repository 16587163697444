import React from 'react'
import Link from 'gatsby-plugin-transition-link/AniLink'
import styled from 'styled-components'
import theme from '../../layout/theme'
import { propTypes } from 'react-bootstrap/esm/Image'

const Wrap = styled.div`
  display: flex;
  align-items: center;
`

const StyledLink = styled(Link)`
  &.active * {
    color: ${({ theme }) => theme.colorYellow} !important;
    font-weight: 500;
  }
  border-bottom: ${props =>
    props.border ? '1px solid ' + props.theme.colorWhite : null};
  &:hover {
    /* opacity: 0.8; */
    color: ${({ theme }) => theme.colorWhite};
  }
`

const MiniLink = props => {
  return (
    <StyledLink
      to={props.to}
      cover
      direction="left"
      // bg={theme.colorYellow}
      bg={theme.colorBlack}
      duration={1}
      {...props}
    >
      {props.children}
    </StyledLink>
  )
}

export default MiniLink
