import styled from 'styled-components'

const ContentWrap = styled.div`
  max-width: 300px;
  margin: 50px auto;

  @media (min-width: 768px) {
    max-width: 500px;
  }
  @media (min-width: 960px) {
    max-width: 600px;
  }
  @media (min-width: 1200px) {
    max-width: 750px;
  }
  @media (min-width: 1400px) {
    max-width: 900px;
  }
  @media (min-width: 1600px) {
    /* max-width: 1100px; */
  }
`

export default ContentWrap
