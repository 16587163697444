export default {
  colorGrey: '#AEAEAE',
  // colorBlack: '#414141',
  colorBlack: '#25283D',
  // colorBlack: '#012231',
  colorYellow: '#FEE903',
  colorYellowDark: '#fcd80d',
  // colorYellow: '#25283D',
  // colorYellow: '#FEC703',
  colorOrange: '#FEC703',
  // colorOrange: '#FF934F',
  colorWhite: '#fff',
  colorRed: '#e74c3c',
}
