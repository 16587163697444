import React from 'react'
import Link from '../../components/link/link'
import styled from 'styled-components'
import Desc from '../../components/desc/desc'

const headerHeight = '60px'

const StyledNav = styled.nav`
  position: fixed;
  /* top: 0; */
  top: ${headerHeight};
  left: 0;
  height: calc(100% - ${headerHeight});
  width: 100%;
  /* background-color: rgba(0, 0, 0, 0.85); */
  background-color: rgba(37, 40, 61, 0.97);
  /* background-color: ${({ theme }) => theme.colorBlack}; */
  z-index: 190;
  padding: 10vh 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  transform: ${props =>
    props.isOpen ? 'translateX(0%)' : 'translateX(-100%)'};

  @media (min-width: 768px) {
    transform: none;
    display: none;
    /* height: ${props => headerHeight}; */
    /* flex-direction: row; */
    /* padding: 0; */
    /* top: 0; */
    /* background-color: transparent; */
    /* background: none; */
    /* justify-content: space-between; */
    /* max-width: 300px; */
  }
`

const Img = styled.img`
  width: 70%;
  margin: 0 auto 30px;
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
`
const LinkImg = styled(Link)`
  @media (min-width: 768px) {
    display: none;
  }
`

const List = styled.ul`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: center;
  /* align-items: center; */
  justify-content: center;
  /* margin-right: auto; */
  margin: 0 auto;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    margin: 0;
  }
`
const StyledDesc = styled(Desc)`
  font-size: 26px;
  color: ${({ theme }) => theme.colorWhite};

  @media (min-width: 768px) {
    font-size: 22px;
    &::before {
      display: none;
    }
  }
`

const StyledLinkWrap = styled.li`
  margin: 10px 0;

  &.active * {
    color: ${({ theme }) => theme.colorYellow} !important;
    font-weight: 500;
  }
  @media (min-width: 768px) {
    margin: 0 10px;
  }
`

const NavMobile = props => {
  return (
    <StyledNav isOpen={props.isOpen}>
      <List>
        {props.data.map((el, i) => {
          return (
            <StyledLinkWrap key={el}>
              <Link
                to={`/${el}`}
                activeClassName="active"
                index={i}
                className={props.isOpen ? 'is-open' : null}
              >
                <StyledDesc yellowRect>
                  {el.replace('-', ' ') || 'główna'}
                </StyledDesc>
              </Link>
            </StyledLinkWrap>
          )
        })}
      </List>
    </StyledNav>
  )
}

export default NavMobile
