import React from 'react'
import styled from 'styled-components'
import Link from '../../components/link/link'
import Desc from '../../components/desc/desc'

const List = styled.ul`
  align-items: center;
  margin: 0;
  display: none;
  @media (min-width: 768px) {
    display: flex;
  }
`
const Li = styled.li`
  margin: 0 10px;
`

const NavDeskt = props => {
  return (
    <List>
      {props.data.map((txt, i) => (
        <Li>
          <Link to={`/${txt}`} activeClassName="active" index={i}>
            <Desc color="colorWhite">{txt.replace('-', ' ') || 'główna'}</Desc>
          </Link>
        </Li>
      ))}
    </List>
  )
}

export default NavDeskt
