import styled from 'styled-components'

const Desc = styled.p`
  margin: 0;
  color: ${props =>
    props.color ? props.theme[props.color] : props.theme.colorGrey};
  font-size: 18px;
  font-weight: ${props => (props.bold ? '500' : '300')};
  text-align: ${props => props.align || 'center'};
  position: ${props => (props.yellowRect ? 'relative' : null)};
  /* max-width: 600px; */
  /* width: 600px; */

  &::before {
    content: ${props => (props.yellowRect ? '""' : null)};
    position: absolute;
    left: -10px;
    top: 50%;
    z-index: 150;
    transform: translateY(-50%);
    height: 60%;
    width: 3px;
    background-color: ${({ theme }) => theme.colorYellow};
  }
  @media (min-width: 768px) {
    font-size: 19px;
  }
  @media (min-width: 1200px) {
    font-size: 20px;
  }
`

export default Desc
