import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  position: fixed !important;
  opacity: 1;
  z-index: 600;
  top: 30px;
  /* top: 60px; */
  /* left: 50px; */
  transform: translateY(-50%);
  left: 5%;
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background-color: ${({ theme }) => theme.colorYellow} !important;
    opacity: 1 !important;
  }
  &.is-active {
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: ${({ theme }) => theme.colorYellow} !important;
      opacity: 1 !important;
    }
    .hamburger-inner {
      /* background-color: ${({ theme }) => theme.colorBlack}; */
    }
  }
  @media (min-width: 768px) {
    display: none !important;
  }
`

const Burger = props => {
  return (
    <Button
      onClick={props.onClick}
      className={`hamburger hamburger--collapse ${props.className}`}
      type="button"
    >
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </Button>
  )
}

export default Burger
